import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Img from "gatsby-image";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "surgeoncircle_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hslogo: file(relativePath: { eq: "hs_logo.png" }) {
      childImageSharp {
        fixed(width:200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default ({data}) => (
  <>
<GatsbySeo title='PanSurg Hidden Strengths Podcast'
      description='Conversations about finding strength though adversity'/>
  <Hero title="The Hidden Strengths Podcast"
        subtitle="Conversations about finding strength though adversity"
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'Hidden Strengths'
  />
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList", 
      "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "PanSurg",
      "item": "http://www.pansurg.org"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Strengths Podcast",
      "item": "http://www.pansurg.org/strengths"  
    }] }} />

  <section className="section">
    <div className="container">
    <div className="content is-thin">
      <div
      data-sal="slide-up"
      data-sal-delay="1000"
      data-sal-duration="1000"
      data-sal-easing="ease">
    <Img className="float-left" style={{margin:"1rem"}} fixed={data.hslogo.childImageSharp.fixed} />
    </div>
        <p><div
      data-sal="slide-up"
      data-sal-delay="1000"
      data-sal-duration="1000"
      data-sal-easing="ease"><b>Welcome to the Hidden Strengths Podcast, where we explore poignant stories from frontline NHS workers and key figures.</b></div></p>
       <p><div
      data-sal="slide-up"
      data-sal-delay="1000"
      data-sal-duration="1000"
      data-sal-easing="ease">Created in recognition of the unparalleled challenges currently facing healthcare systems and their staff around the world from COVID-19, this month PanSurg has launched a series of conversations between NHS workers and key figures to share their experience of adversity and perseverance.</div></p>
        <p><div
      data-sal="slide-up"
      data-sal-delay="1000"
      data-sal-duration="1000"
      data-sal-easing="ease">We focus on issues such as endurance, emotional challenges, cooperation and 
team collaboration, the series sees the first British citizen to go into space Helen Sharman, professional boxer and politician Manny Pacquiao and British journalist Frank Gardner in conversations with NHS workers talking through their own stories and experiences of adversity.</div></p>
<p><div
      data-sal="slide-up"
      data-sal-delay="1000"
      data-sal-duration="1000"
      data-sal-easing="ease">Looking to the future, the hope is that these stories will be provide an interesting and insightful platform for those listening, but also connect key workers across the globe through their human experience of the pandemic, regardless of their background. </div></p>
     <div
      data-sal="slide-up"
      data-sal-delay="500"
      data-sal-duration="1000"
      data-sal-easing="ease">
        <hr />
     <h1 className="title has-text-darkBlue is-spaced">
        Latest Episodes
      </h1>
      <Accordion allowZeroExpanded>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       <b>Episode 1:</b> Manny Pacquiao 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Manny Pacquiao speaks with Ralph and Brenda, a married couple that have been working in the NHS for 20 years about how to overcome difficult situations, personal challenges and how to find the strength to persevere.  
                    </p>
                    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/890327290&color=%23ab2a48&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize:10, color:"#cccccc", lineBreak:"anywhere", wordBreak:"normal", overflow:"hidden", textOverflow:"ellipsis", fontWeight:100}}><a href="https://soundcloud.com/user-777214204" title="PanSurg" target="_blank" style={{color:"#cccccc"}}>PanSurg</a> · <a href="https://soundcloud.com/user-777214204/hidden-strengths-ralph-brenda-deocampo-and-manny-pacquiao" title="Hidden Strengths: Ralph &amp; Brenda Deocampo and Manny Pacquiao" target="_blank" style={{color:"#cccccc"}}>Hidden Strengths: Ralph &amp; Brenda Deocampo and Manny Pacquiao</a></div>
                    
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>Episode 2:</b> Helen Sharman 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Helen Sharman talks to Mr Derek Yeung a surgical registrar and research fellow at Imperial College Hospital & Trust about endurance, her own anecdotes about training to go into space and working towards a collective goal for the greater good.
                    </p>
                    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/881856898&color=%23ab2a48&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize:10, color:"#cccccc", lineBreak:"anywhere", wordBreak:"normal", overflow:"hidden", textOverflow:"ellipsis", fontWeight:100}}><a href="https://soundcloud.com/user-777214204" title="PanSurg" target="_blank" style={{color:"#cccccc"}}>PanSurg</a> · <a href="https://soundcloud.com/user-777214204/hidden-strengths-derek-yeung-helen-sharman" title="Hidden Strengths: Derek Yeung &amp; Helen Sharman" target="_blank" style={{color:"#cccccc"}}>Hidden Strengths: Derek Yeung &amp; Helen Sharman</a></div>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>Episode 3:</b> Frank Gardner 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Frank Gardner chats to Dr Mike O’Connor an Anaesthetic & ITU Consultant at Northwick Park Hospital about dealing with the sudden and overwhelming influx of dangerously or seriously unwell patients and his own unexpected experience of being shot.</p>
                    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/881856883&color=%23ab2a48&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize:10, color:"#cccccc", lineBreak:"anywhere", wordBreak:"normal", overflow:"hidden", textOverflow:"ellipsis", fontWeight:100}}><a href="https://soundcloud.com/user-777214204" title="PanSurg" target="_blank" style={{color:"#cccccc"}}>PanSurg</a> · <a href="https://soundcloud.com/user-777214204/hidden-strengths-dr-mike-1" title="Hidden Strengths: Dr Mike O&#x27;Connor &amp; Frank Gardner" target="_blank" style={{color:"#cccccc"}}>Hidden Strengths: Dr Mike O&#x27;Connor &amp; Frank Gardner</a></div>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      </div>
      </div>
    </div>
  </section>
  </>
)
